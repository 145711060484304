
import { defineComponent } from "vue";
import { useStore } from "../store";

export default defineComponent({
    name: "Menu",
    data() {
        return {
            difficulty: "4500",
            lockInterface: false
        };
    },
    setup() {
        const store = useStore();
        const generateSudoku = (difficulty: number) =>
            store.commit("GenerateNew", { difficulty });
        return { generateSudoku };
    },
    methods: {
        generateNewSudoku(e: Event) {
            this.lockInterface = true;
            try{
                this.generateSudoku(parseInt(this.$data.difficulty, 10));
            }
            catch {
                this.generateSudoku(4500);
            }
             this.lockInterface = false;
        }
    }
});
