
import { defineComponent, computed } from "vue";
import { useStore } from "../store";

export default defineComponent({
    data() {
        const store = useStore();
        const index = this.index;
        const inputRegex = /^\d$/i;
        const setCell = (value: number) =>
            store.commit("SetCell", { index, value, quickCheck: false });
        const value = computed({
            get: () =>
                Number.isNaN(store.getters.sudoku[index].value)
                    ? ""
                    : store.getters.sudoku[index].value,
            set: val => setCell(typeof val === "number" ? val : NaN)
        });
        const valid = computed(() => store.getters.sudoku[index].valid);
        const enabled = computed(() => store.getters.sudoku[index].enabled);
        const matched = computed(
            () => store.getters.selected === store.getters.sudoku[index].value
        );
        const select = (e: Event) => {
            const cell = e?.target;
            if (cell instanceof HTMLInputElement) {
                if (!inputRegex.test(cell.value) && cell.value !== "NaN")
                    cell.value = "";
                cell.focus();
                cell.select();
            }
            store.commit("Select", { index });
        };
        const selectDifferentCell = (index: number) => {
            const cell = document.querySelector(`#cell-${index}-input`);
            if (cell instanceof HTMLInputElement) {
                cell.focus();
                cell.select();
            }
        };
        return {
            value,
            valid,
            enabled,
            matched,
            select,
            selectDifferentCell
        };
    },
    name: "Cell",
    props: {
        index: { type: Number, required: true }
    }
});
