
import { defineComponent } from "vue";
import Sudoku from "./components/Sudoku.vue";
import Menu from "./components/Menu.vue";

export default defineComponent({
    name: "App",
    components: {
        Sudoku,
        Menu
    }
});
