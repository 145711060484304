
import { defineComponent, computed } from "vue";
import Cell from "./Cell.vue";
import { useStore } from "../store";

export default defineComponent({
    name: "Sudoku",
    setup() {
        const store = useStore();
        const sudoku = computed(() => store.getters.sudoku);
        const solved = computed(() => store.getters.isSolved);
        return {
            sudoku,
            solved
        };
    },
    components: { Cell }
});
